<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
    <span v-html="message"></span>
    <template v-slot:action>
      <v-btn text @click="close" icon small><v-icon small>mdi-close-circle</v-icon></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'FlashMessage',
  data: function () {
    return {
      snackbar: false,
      timeout: 3000
    }
  },
  computed: {
    color () {
      return this.$store.state.flashMessage.color
    },
    message () {
      return this.$store.state.flashMessage.message
    }
  },
  watch: {
    message () {
      this.snackbar = (this.$store.state.flashMessage.message !== null)
    },
    snackbar () {
      if (this.snackbar !== true) {
        this.$store.state.flashMessage.message = null
      }
    }
  },
  methods: {
    close () {
      this.snackbar = false
    }
  }
}
</script>
