<template>
  <div class="news-link" v-if="items.length > 0">
    <v-menu offset-y max-width="400">
      <template #activator="menuActivator">
        <v-tooltip bottom>
          <template #activator="tooltipActivator">
            <v-btn class="news-icon" v-bind="menuActivator.attrs" v-on="{ ...menuActivator.on, ...tooltipActivator.on }" @click="lastReadNews = new Date().toISOString()" text>
              <v-badge color="red" dot overlap :value="isRead !== true">
                <v-icon size="20" color="primary">mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>お知らせ</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <template v-for="(item, index) in items">
          <v-list-item :key="`news-1-${index}`" @click.stop="openDialog(item.id)">
            <v-list-item-content>
              <v-list-item-subtitle class="mb-2">
                {{ item.create_at | moment("YYYY年M月D日") }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.subject }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < items.length - 1" :key="`news-2-${index}`"></v-divider>
        </template>
      </v-list>
    </v-menu>
    <v-dialog v-model="dialog" scrollable max-width="640">
      <v-card v-if="activeItem">
        <v-card-title class="justify-space-between">
          <span>{{ activeItem.subject }} </span>
          <span class="subtitle-2">{{ activeItem.create_at | moment("YYYY/MM/DD") }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3" style="white-space: pre-line;">
          <span v-text="activeItem.content" v-linkified></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">閉じる</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'NewsTicker',
  data: () => ({
    items: [],
    dialog: false,
    dialogShowID: null,
    lastReadNews: null
  }),
  created: function () {
    this.readDataFromXML()
    this.lastReadNews = localStorage.getItem('lastReadClientNews')
  },
  computed: {
    isRead: function () {
      const lastReadNews = this.lastReadNews
      if (lastReadNews === null) {
        return false
      }
      const lastReadNewsDate = new Date(lastReadNews)
      const lastNewsDate = new Date(this.items[0].create_at)
      return lastReadNewsDate >= lastNewsDate
    },
    activeItem: function () {
      return this.items.find((v) => v.id === this.dialogShowID)
    }
  },
  watch: {
    lastReadNews: function (val) {
      localStorage.setItem('lastReadClientNews', val)
    }
  },
  methods: {
    readDataFromXML: function () {
      const _self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news`)
        .then(res => {
          _self.items = res.data.data
          console.log(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    openDialog: function (id) {
      this.dialogShowID = id
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.news-link {
  height: inherit;
  .news-icon {
    height: inherit;
  }
}
</style>
