<template>
  <v-app>
    <!--------------------------------- Toolbar -------------------------------- -->
    <v-app-bar app dense elevate-on-scroll clipped-right>
      <v-app-bar-nav-icon @click="sidenavi_drawer=!sidenavi_drawer" v-show="!sidenavi_drawer"></v-app-bar-nav-icon>
      <v-img src="@/assets/logo.svg" v-show="!sidenavi_drawer" @click="$router.push({ name: 'client:top'},() => {})" max-width="26" aspect-ratio="1"></v-img>
      <v-spacer class="d-sm-inline d-md-none"></v-spacer>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="planType == 'point'">
        <template v-slot:activator="{ on, attrs }">
          <div class="point-remaind" v-bind="attrs" v-on="on">
            <v-img src="@/assets/ico/ico_point.svg" width="16" class="d-inline-block" />
            <span>{{ loginUserInfo.end_user.current_end_user_plan.remainder_point }}<span class="unit">Pt</span> / {{ loginUserInfo.end_user.current_end_user_plan.max_point }}<span class="unit">Pt</span></span>
          </div>
        </template>
        <span>残りポイント数</span>
      </v-tooltip>
      <NewsTicker />
      <v-toolbar-items>
        <v-menu offset-y max-width="250">
          <template v-slot:activator="{on}">
            <v-btn v-on="on" text>
              <LoginAvatar :user="loginUserInfo" :size="32" :tooltip="false" />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item two-line :to="{ name: 'client:login_show', params:{ id: loginUserInfo.id } }">
              <v-list-item-content>
                <v-list-item-title>{{ loginUserInfo.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ loginUserInfo.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(item, i) in personal_menu" :key="i" :to="item.link">
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'client:user_show', params: { section: 'support' }}">
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>サポート担当</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-menu offset-x left max-width="180">
              <template v-slot:activator="{on}">
                <v-list-item v-on="on">
                  <v-list-item-icon><v-icon size="20">mdi-help-circle-outline</v-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>トコトンについて</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item href="https://tokoton.biz/news/" target="_blank" rel="noopener">
                  <v-list-item-icon><v-icon size="20">mdi-bell</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title>お知らせ</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item href="https://tokoton.biz/rule/" target="_blank" rel="noopener">
                  <v-list-item-icon><v-icon size="20">mdi-file-document-outline</v-icon></v-list-item-icon>
                  <v-list-item-content><v-list-item-title>トコトン利用規約</v-list-item-title></v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item @click="logout">
              <v-list-item-icon><v-icon>mdi-logout-variant</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>ログアウト</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <!-------------------------------- SideNavi -------------------------------- -->
    <v-navigation-drawer app  v-model="sidenavi_drawer" width="220" mobile-breakpoint="960" id="main-nav">
      <template v-slot:prepend>
          <v-img height="90" src="@/assets/logo2022_v.svg" @click="$router.push({ name: 'client:top'},() => {})" contain class="my-4"></v-img>
      </template>
        <v-list dense nav class="mt-1">
          <template v-for="(item, i) in nav_lists"><template v-if="item.disable !== true">
            <v-list-item v-if="!item.lists" :to="item.link" exact :key="i">
              <v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-else :key="i" v-model="item.active" :prepend-icon="item.icon" no-action color="secondary">
              <template v-if="item.name" v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
              </template>
              <!-- sub list items -->
              <template v-if="item.lists">
                <v-list-item v-for="(list, i) in item.lists" :key="i" :to="list.link">
                  <v-list-item-content>
                    <v-list-item-title>{{ list.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-chip v-show="list.count" x-small color="secondary" class="font-weight-bold">{{ list.count }}</v-chip>
                </v-list-item>
              </template>
            </v-list-group>
          </template></template>
        </v-list>
          <template v-slot:append>
            <v-row no-gutters>
              <v-col class="pb-3 text-center">
                <v-btn @click="addNewRequest()" color="primary" fab large class="mb-2">
                  <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
                </v-btn>
                <span class="d-block text-body-1">新規依頼</span>
              </v-col>
            </v-row>
          </template>
    </v-navigation-drawer>

    <!---------------------------------- Main ---------------------------------- -->
    <v-main>
      <FlashMessage />
      <router-view v-on:addNewRequest="addNewRequest()" :user_plan="loginUserInfo.end_user.current_end_user_plan" />
    </v-main>

    <!--------------------------------- Footer --------------------------------- -->
    <!--
    <v-footer app inset absolute>
      <small>&copy; 2013-2022 <a href="https://phonogram.co.jp" target="_blank">Phonogram</a> Inc.</small>
    </v-footer>
    -->

    <new-request ref="new_request"></new-request>
  </v-app>
</template>

<script>
import FlashMessage from '@/components/FlashMessage.vue'
import NewsTicker from '@/components/Client/NewsTicker.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'
import NewRequest from '../components/NewRequest.vue'

const REQUEST_NAV_INDEX = 1 // 「依頼」のナビゲーションリストのインデックス
const DIRECTION_NAV_INDEX = 2 // 「制作案件」のナビゲーションリストのインデックス

export default {
  name: 'Client',
  components: {
    FlashMessage,
    NewsTicker,
    LoginAvatar,
    NewRequest
  },
  data: function () {
    return {
      sidenavi_drawer: null, // for toggle navigation-drawer
      personal_menu: [],
      nav_lists: [
        {
          name: 'ダッシュボード',
          icon: 'mdi-view-dashboard',
          link: { name: 'client:top' }
        },
        {
          name: '依頼',
          icon: 'mdi-checkbox-multiple-marked-outline',
          active: localStorage.getItem('nav_list_request_active') !== null ? JSON.parse(localStorage.getItem('nav_list_request_active')) : true,
          lists: [
            {
              name: '全て',
              link: { name: 'client:request_index', params: { fillter: 'all' } },
              count: null
            },
            {
              name: '進行中',
              link: { name: 'client:request_index', params: { fillter: 'active' } },
              count: null
            },
            {
              name: '自分の確認待ち',
              link: { name: 'client:request_index', params: { fillter: 'review' } },
              count: null
            }
          ]
        },
        {
          name: '制作案件',
          icon: 'mdi-hammer-wrench',
          link: { name: 'client:direction_index' },
          disable: true
        },
        {
          name: '契約情報',
          icon: 'mdi-text-box-check-outline',
          lists: [
            {
              name: '契約情報',
              link: { name: 'client:user_show', params: { section: 'info' } }
            },
            {
              name: 'サービス一覧',
              link: { name: 'client:user_show', params: { section: 'service' } }
            },
            {
              name: '会社情報',
              link: { name: 'client:user_show', params: { section: 'company' } }
            },
            {
              name: 'サポート担当・その他',
              link: { name: 'client:user_show', params: { section: 'support' } }
            }
          ]
        },
        {
          name: 'アカウント',
          icon: 'mdi-account-multiple',
          link: { name: 'client:user_show_logins' }
        }
      ]
    }
  },
  created: function () {
    this.readDataFromAPI()
    // 「制作案件」が有効なユーザーの場合、ナビゲーションリストを有効にする
    if (this.useDirectionManagement === true) {
      this.nav_lists[DIRECTION_NAV_INDEX].disable = false
    }
  },
  mounted () {
    document.body.id = 'client'
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    useDirectionManagement: function () {
      return this.$store.getters.getUseDirectionManagement
    },
    pageTitle: function () {
      return this.$route.meta.title
    },
    planType () {
      return this.loginUserInfo.end_user.current_end_user_plan.plan_mst.contract_model
    }
  },
  watch: {
    nav_lists: {
      handler: function () {
        localStorage.setItem('nav_list_request_active', this.nav_lists[REQUEST_NAV_INDEX].active)
      },
      deep: true
    },
    loginUserInfo () {
      if (this.loginUserInfo) {
        this.personal_menu.push({
          name: '個人設定',
          icon: 'mdi-account-cog',
          link: { name: 'admin:settings_login_show', params: { id: this.loginUserInfo.id } } // FIXME: リンク先間違い
        })
      }
    }
  },
  methods: {
    logout () {
      this.userSignOut()
    },
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/count`)
        .then(res => {
          this.nav_lists[1].lists[0].count = res.data.all
          this.nav_lists[1].lists[1].count = res.data.active
          this.nav_lists[1].lists[2].count = res.data.review
        })
        .catch(err => {
          console.log(err)
        })
    },
    addNewRequest () { // open new request dialog (component)
      this.$refs.new_request.open()
    }
  }
}
</script>
<style lang="scss" scoped>
.point-remaind {
   @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

</style>
